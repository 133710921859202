<template>
  <div>
    <v-card flat>
      <v-card-title>
        <label class="primary--text px-5 font-weight-bold bank-page-title ma-auto">{{ $t(`label.bankAccount`) }}</label>
      </v-card-title>
    </v-card>
    <v-card>
      <div>
        <v-card-title>
          <label class="ma-auto font-weight-bold subtitle-1 text-capitalize">{{ `${memberBankAccounts.length > 0 ? $t(`label.chooseBankAccount`) : $t(`label.noBankAccount`)} ` }}</label>
        </v-card-title>

        <div class="pb-2 pt-3">
          <div class="bank-account-card-container">
            <v-row no-gutters justify="center" class="pb-6" v-for="bankAccount in memberBankAccounts" :key="bankAccount.code">
              <v-card class="bank-account-card" :class="bankAccount.bankName == '' || bankAccount.accountNumber == '' || bankAccount.accountName == '' ? 'inComplete' : ''">
                <div class="pa-0 px-2 py-2">
                  <v-card-text class="pa-0 px-2 body-1 black--text font-weight-bold">{{ bankAccount.bankName }}</v-card-text>
                  <v-card-text class="pa-0 px-2 body-1 black--text font-weight-bold">{{ bankAccount.accountNumber }}</v-card-text>
                  <v-card-text class="pa-0 px-2">{{ bankAccount.accountName }}</v-card-text>
                  <v-card-text class="pa-0 px-2 overline red--text" v-if="bankAccount.bankName == '' || bankAccount.accountNumber == '' || bankAccount.accountName == ''">
                    {{ $t(`label.bankAccountIsNotComplete`) }}
                  </v-card-text>
                </div>
                <v-divider></v-divider>
                <v-row justify="end" no-gutters>
                  <v-col class="shrink">
                    <v-card-actions class="py-0">
                      <v-btn text min-width="auto" class="text-uppercase font-weight-bold" :ripple="false" @click="openEditBankAccountDialog(bankAccount)">{{ $t(`label.edit`) }}</v-btn>
                      <label>/</label>
                      <v-btn text min-width="auto" class="text-uppercase font-weight-bold" :ripple="false" @click="confirmationDeleteMemberBankAccount(bankAccount)">{{ $t(`label.delete`) }}</v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </div>
        </div>
        <div class="pb-2 text-center">
          <v-btn text :ripple="false" class="text-uppercase font-weight-bold text-center secondary--text" @click="openNewBankAccountDialog">{{ $t(`label.addBankAccount`) }}</v-btn>
        </div>
      </div>
    </v-card>

    <app-dialog :dialogShow="bankAccountDialogShow" :max-width="600" :title="$t(`label.bankAccount`)" :closeAction="this.closeBankAccountDialog">
      <app-account
        :banks="banks"
        :showFullBankForm="true"
        :bankAccountDetail="bankAccountObj"
        :isUpdateBank="true"
        :closeNewBankAccountDialog="this.closeBankAccountDialog"
        @updateBankAccountObject="submitBankAccountData"
      ></app-account>
    </app-dialog>
  </div>
</template>
<script>
import AppAccount from '@/components/bank/account.vue'
import { SESSION } from '@/constants/constants'
import { locale, uiHelper, errorCodeHelper } from '@/util'
import {
  AFFILIATE_PAYMENT_DELETE_MEMBER_BANK_ACCOUNT,
  AFFILIATE_PAYMENT_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE,
  AFFILIATE_PAYMENT_UPDATE_MEMBER_BANK_ACCOUNT,
  AFFILIATE_PAYMENT_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE,
  AFFILIATE_PAYMENT_NEW_MEMBER_BANK_ACCOUNT,
  AFFILIATE_PAYMENT_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE
} from '@/store/affiliate.payment.module'
export default {
  name: 'bankAccount',
  metaInfo: {
    title: `${locale.getMessage('meta.bankInfo_title')}`,
    titleTemplate: `%s`,
    meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.bankInfo_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.bankInfo_keyword')}` }]
  },
  components: {
    AppAccount
  },
  data: () => ({
    isUpdateBank: true,
    bankAccountDialogShow: false,
    bankAccountObj: {
      memberBankAccountId: '',
      memberBankAccountName: '',
      memberBankAccountNumber: '',
      memberBankName: '',
      memberBankProvince: '',
      memberBankBranch: '',
      memberBankCity: '',
      saveNewBank: false,
      memberCode: localStorage.getItem(SESSION.USERNAME),
      isUpdateBank: false
    }
  }),
  computed: {
    banks() {
      return this.$store.state.affiliatePayment.banks
    },
    memberBankAccounts() {
      return this.$store.state.affiliatePayment.memberBankAccounts.filter(x => x.id != '0')
    },
    deleteMemberBankAccountResponseComplete() {
      return this.$store.state.affiliatePayment.deleteMemberBankAccountResponse.complete
    },
    updateMemberBankAccountResponseComplete() {
      return this.$store.state.affiliatePayment.updateMemberBankAccountResponse.complete
    },
    newMemberBankAccountResponseComplete() {
      return this.$store.state.affiliatePayment.newMemberBankAccountResponse.complete
    }
  },
  watch: {
    deleteMemberBankAccountResponseComplete() {
      let response = this.$store.state.affiliatePayment.deleteMemberBankAccountResponse
      if (response.complete) {
        this.deleteMemberBankAccountResponseCompleteDialog(response)
        this.$parent.getMemberBankAccount()
        this.$store.dispatch(`${AFFILIATE_PAYMENT_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE}`)
      }
    },
    updateMemberBankAccountResponseComplete() {
      let response = this.$store.state.affiliatePayment.updateMemberBankAccountResponse
      if (response.complete) {
        this.updateMemberBankAccountResponseCompleteDialog(response)
        this.$parent.getMemberBankAccount()
        this.$store.dispatch(`${AFFILIATE_PAYMENT_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE}`)
      }
    },
    newMemberBankAccountResponseComplete() {
      let response = this.$store.state.affiliatePayment.newMemberBankAccountResponse
      if (response.complete) {
        this.newMemberBankAccountResponseCompleteDialog(response)
        this.$parent.getMemberBankAccount()
        this.$store.dispatch(`${AFFILIATE_PAYMENT_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE}`)
      }
    }
  },
  created() {
    this.$parent.getBank()
    this.$parent.getMemberBankAccount()
  },
  methods: {
    confirmationDeleteMemberBankAccount(data) {
      let dialog = this.$parent.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`message.deleteBankAccount`)
      dialog.message.push(locale.getMessage(`message.confirmDeleteBankAccount`))
      dialog.message.push(locale.getMessage('field.bankName') + ' : ' + data.bankName)
      dialog.message.push(locale.getMessage('field.accountName') + ' : ' + data.accountName)
      dialog.message.push(locale.getMessage('field.accountNumber') + ' : ' + data.accountNumber)

      dialog.button.push({
        title: locale.getMessage(`label.confirm`),
        action: () => this.deleteMemberBankAccount(data)
      })

      dialog.button.push({
        title: locale.getMessage(`label.cancel`),
        action: this.$parent.$parent.closePageDialog
      })

      this.$parent.$parent.openPageDialog(dialog)
    },
    deleteMemberBankAccount(data) {
      this.$parent.$parent.closePageDialog
      let obj = {
        memberBankAccountId: data.id
      }
      this.$store.dispatch(`${AFFILIATE_PAYMENT_DELETE_MEMBER_BANK_ACCOUNT}`, { obj })
    },
    deleteMemberBankAccountResponseCompleteDialog(response) {
      let dialog = this.$parent.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)

      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.$parent.$parent.closePageDialog
        })
      } else {
        dialog.message.push(locale.getMessage(`message.memberBankAccountDeleted`))

        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.$parent.$parent.closePageDialog
        })
      }

      this.$parent.$parent.openPageDialog(dialog)
    },
    updateBankAccountData(obj) {
      this.$store.dispatch(`${AFFILIATE_PAYMENT_UPDATE_MEMBER_BANK_ACCOUNT}`, { obj })
    },
    updateMemberBankAccountResponseCompleteDialog(response) {
      let dialog = this.$parent.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)

      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.$parent.$parent.closePageDialog
        })
      } else {
        this.closeBankAccountDialog()
        dialog.message.push(locale.getMessage(`message.memberBankAccountUpdated`))

        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.$parent.$parent.closePageDialog
        })
      }

      this.$parent.$parent.openPageDialog(dialog)
    },
    newBankAccountData(bankAccountObj) {
      this.$store.dispatch(`${AFFILIATE_PAYMENT_NEW_MEMBER_BANK_ACCOUNT}`, { bankAccountObj })
    },
    newMemberBankAccountResponseCompleteDialog(response) {
      let dialog = this.$parent.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)

      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.$parent.$parent.closePageDialog
        })
      } else {
        this.closeBankAccountDialog()
        dialog.message.push(locale.getMessage(`message.memberBankAccountCreated`))

        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.$parent.$parent.closePageDialog
        })
      }

      this.$parent.$parent.openPageDialog(dialog)
    },
    submitBankAccountData(data) {
      if (data.isUpdateBank) {
        this.updateBankAccountData(data)
      } else {
        this.newBankAccountData(data)
      }
    },
    openEditBankAccountDialog(data) {
      this.bankAccountObj = {
        memberBankAccountId: data.id,
        memberBankAccountName: data.accountName,
        memberBankAccountNumber: data.accountNumber,
        memberBankName: data.bankName,
        memberBankProvince: data.bankProvince,
        memberBankBranch: data.bankBranch,
        memberBankCity: data.bankCity,
        saveNewBank: true,
        memberCode: localStorage.getItem(SESSION.USERNAME),
        isUpdateBank: true
      }
      this.bankAccountDialogShow = true
    },
    openNewBankAccountDialog() {
      this.bankAccountObj = null
      this.bankAccountDialogShow = true
    },
    closeBankAccountDialog() {
      this.bankAccountDialogShow = false
    }
  }
}
</script>

<style lang="scss">
.bank-account-card-container {
  max-height: 550px;
  overflow-y: auto;
}
</style>
