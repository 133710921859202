<template>
  <div>
    <v-row class="px-4 pb-2" justify="end">
      <v-col class="align" cols="12">
        <label class="font-weight-bold mr-2 body-2">{{ $t(`label.searchDate`) }}</label>
        <div class="d-inline-block mx-1 transaction-page-search-date-field-box">
          <v-menu ref="menu" v-model="searchDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                color="secondary"
                :placeholder="$t(`label.from`)"
                append-icon="event"
                outlined
                class="input-field"
                hide-details
                dense
                readonly
                v-on="on"
                v-model="displaySearchDate"
              ></v-text-field>
            </template>
            <v-date-picker
              range
              v-model="searchDate"
              scrollable
              color="secondary"
              :min="minSelectableDate"
              :max="maxSelectableDate"
              :locale="language"
              :selected-items-text="`${searchDate[0]} - ${searchDate[1]}`"
              @change="reorderDateRangeValue"
            ></v-date-picker>
          </v-menu>
        </div>
        <v-btn tile class="mx-1" width="40" height="40" color="secondary" @click="searchGameReport()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      mobile-breakpoint="800"
      class="transaction-table px-4"
      :headers="tableHeaders"
      :items="affiliateGameDetail.list"
      hide-default-footer
      :expanded.sync="expandedData"
      item-key="index"
      :no-data-text="$t(`message.noDataAvaliable`)"
    >
      <template v-slot:body="{ items, expand, isExpanded, isMobile }">
        <tbody>
          <template v-if="!isMobile">
            <template v-for="item in items">
              <tr :key="item.index">
                <td>{{ $t(`label.${item.productType}`) }}</td>
                <td>{{ item.memberCount }}</td>
                <td>{{ item.winLoss | currency }}</td>
                <td>{{ item.turnover | currency }}</td>
                <td>
                  <span>
                    <v-btn icon @click="getProductTypeReport(item), expand(item, !isExpanded(item))">
                      <v-icon>{{ isExpanded(item) ? `keyboard_arrow_up` : `keyboard_arrow_down` }}</v-icon>
                    </v-btn>
                  </span>
                </td>
              </tr>
              <template v-if="expandedData.find(x => x.productType == item.productType)">
                <tr v-for="provider in expandedData.find(x => x.productType == item.productType).provider" :key="provider.providerCode">
                  <td>
                    <label class="pl-4">{{ $t(`gameVendor.${provider.providerCode}_short`) }}</label>
                  </td>
                  <td>{{ provider.memberCount }}</td>
                  <td>{{ provider.winLoss | currency }}</td>
                  <td>{{ provider.turnover | currency }}</td>
                  <td></td>
                </tr>
              </template>
            </template>

            <tr v-if="items.length <= 0">
              <td :colspan="tableHeaders.length" class="text-center">{{ $t(`message.noDataAvaliable`) }}</td>
            </tr>
          </template>

          <template v-else>
            <template v-for="item in items">
              <tr :key="item.index">
                <td>
                  <v-row no-gutters class="py-2">
                    <v-col class="text-center subtitle-1 font-weight-bold">{{ $t(`label.${item.productType}`) }}</v-col>
                  </v-row>
                  <v-row no-gutters class="py-2">
                    <v-col>{{ $t('label.loginCount') }}</v-col>
                    <v-col class="text-right">{{ item.memberCount }}</v-col>
                  </v-row>
                  <v-row no-gutters class="py-2">
                    <v-col>{{ $t('label.memberWinLoss') }}</v-col>
                    <v-col class="text-right">{{ item.winLoss | currency }}</v-col>
                  </v-row>
                  <v-row no-gutters class="py-2">
                    <v-col>{{ $t('label.memberTurnover') }}</v-col>
                    <v-col class="text-right">{{ item.turnover | currency }}</v-col>
                  </v-row>

                  <v-row no-gutters class="py-2">
                    <v-col>
                      <app-button
                        customClass="buttonPrimary white--text mt-0 mb-2 body-2 pa-2"
                        :title="isExpanded(item) ? $t('label.close') : $t('label.moreDetail')"
                        :action="
                          () => {
                            getProductTypeReport(item), expand(item, !isExpanded(item))
                          }
                        "
                      ></app-button>
                    </v-col>
                  </v-row>
                </td>
              </tr>
              <template v-if="expandedData.find(x => x.productType == item.productType)">
                <tr v-for="provider in expandedData.find(x => x.productType == item.productType).provider" :key="provider.providerCode">
                  <td>
                    <v-row no-gutters class="py-2">
                      <v-col>{{ $t('label.vendor') }}</v-col>
                      <v-col class="text-right">{{ $t(`gameVendor.${provider.providerCode}_short`) }}</v-col>
                    </v-row>
                    <v-row no-gutters class="py-2">
                      <v-col>{{ $t('label.memberCount') }}</v-col>
                      <v-col class="text-right">{{ provider.memberCount }}</v-col>
                    </v-row>
                    <v-row no-gutters class="py-2">
                      <v-col>{{ $t('label.memberWinLoss') }}</v-col>
                      <v-col class="text-right">{{ provider.winLoss | currency }}</v-col>
                    </v-row>
                    <v-row no-gutters class="py-2">
                      <v-col>{{ $t('label.memberTurnover') }}</v-col>
                      <v-col class="text-right">{{ provider.turnover | currency }}</v-col>
                    </v-row>
                  </td>
                </tr>
              </template>
            </template>
            <tr v-if="items.length <= 0">
              <td class="text-center">{{ $t(`message.noDataAvaliable`) }}</td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>

    <v-row class="px-4" justify="end">
      <v-col class="align body-2" cols="12">
        <label>{{ `${$t(`label.lastUpdatedDate`)}: ` }}</label>
        <label>{{ affiliateGameDetail.lastUpdatedDate | longDate }}</label>
      </v-col>
    </v-row>
    <v-row no-gutters class="px-4">
      <v-col cols="6" sm="4" lg="3">
        <div class="mx-2 my-1">
          <p class="body-2 ma-0 my-2 text-uppercase font-weight-bold secondary--text">{{ $t(`label.totalMemberWinLoss`) }}</p>
          <v-card tile class="px-2 py-1">{{ affiliateGameDetail.recordSummary.winLoss | currency }}</v-card>
        </div>
      </v-col>

      <v-col cols="6" sm="4" lg="3">
        <div class="mx-2">
          <p class="body-2 ma-0 my-2 text-uppercase font-weight-bold secondary--text">{{ $t(`label.totalMemberTurnover`) }}</p>
          <v-card tile class="px-2 py-1">{{ affiliateGameDetail.recordSummary.turnover | currency }}</v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { AFFILIATE_PRODUCT_REPORT, AFFILIATE_PRODUCT_DETAIL_REPORT } from '@/store/affiliate.module'
import { locale, uiHelper } from '@/util'
import { SHARED } from '@/constants/constants'
export default {
  name: 'affiliateGameReport',
  data: () => ({
    language: uiHelper.getLanguage(),
    minSelectableDate: new Date(new Date().setDate(new Date().getDate() - 90)).toISOString().substr(0, 10),
    maxSelectableDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    searchDate: [new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substr(0, 10), new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)],
    searchDateMenu: false,

    expandedData: [],
    tableHeaders: [
      {
        text: locale.getMessage('label.productType'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        value: 'productType',
        sortable: true
      },
      {
        text: locale.getMessage('label.memberCount'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        value: 'memberCount',
        sortable: true
      },
      {
        text: locale.getMessage('label.memberWinLoss'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        value: 'winLoss',
        sortable: true
      },
      {
        text: locale.getMessage('label.memberTurnover'),
        align: 'left',
        class: 'body-2 font-weight-bold',
        value: 'turnover',
        sortable: true
      },
      {
        text: '',
        align: 'left',
        class: 'body-2 font-weight-bold',
        width: '30'
      }
    ],
    searchCriteria: {
      mode: 3,
      pageNumber: SHARED.DEFAULT_PAGE_NUMBER,
      pageSize: SHARED.DEFAULT_PAGE_SIZE,
      startDate: '',
      endDate: ''
    }
  }),
  computed: {
    displaySearchDate() {
      return this.searchDate.join(` ${locale.getMessage('label.to')} `)
    },

    affiliateGameDetail() {
      return this.$store.state.affiliate.gameDetail
    }
  },
  created() {
    this.getGameReport()
  },
  methods: {
    reorderDateRangeValue(x) {
      if (new Date(x[0]) >= new Date(x[1])) {
        let startDate = x[1]
        let endDate = x[0]
        this.searchDate[0] = startDate
        this.searchDate[1] = endDate
      }
    },
    getGameReport() {
      this.expandedData = []
      let obj = {
        mode: this.searchCriteria.mode,
        size: this.searchCriteria.pageSize,
        page: this.searchCriteria.pageNumber,
        sort: 'report_date DESC',
        startDate: this.searchDate[0],
        endDate: this.searchDate[1]
      }

      this.$store.dispatch(`${AFFILIATE_PRODUCT_REPORT}`, { obj })
    },
    getProductTypeReport(item) {
      if (item.provider.length <= 0) {
        let obj = {
          mode: this.searchCriteria.mode,
          size: this.searchCriteria.pageSize,
          page: this.searchCriteria.pageNumber,
          sort: 'report_date DESC',
          startDate: this.searchDate[0],
          endDate: this.searchDate[1],
          productType: item.productType
        }

        this.$store.dispatch(`${AFFILIATE_PRODUCT_DETAIL_REPORT}`, { obj })
      }
    },
    changePage(targetPage) {
      this.searchCriteria.pageNumber = targetPage
      this.getGameReport()
    },
    searchGameReport() {
      this.changePage(1)
    }
  }
}
</script>

<style lang="scss">
.transaction-page-search-date-field-box {
  width: 280px;
}
</style>
